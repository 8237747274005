import './style.scss';
import {Map, Overlay, View} from 'ol';
import TileLayer from 'ol/layer/Tile';

import Config from './config'
import {OSM, TileDebug, TileWMS} from "ol/source";
import {TileGrid} from "ol/tilegrid";
import {get as getProjection} from 'ol/proj.js';
import {getWidth} from 'ol/extent.js';
import {attachLayerCheckbox, WFSSource} from "./src/ol-ext";

import marker from './static/marker.png'
import markerPlanned from './static/marker-planned.png'
import {defaults as defaultInteractions, Link} from 'ol/interaction';

import {PointInfo} from "./src/pointInfo";
import LayerGroup from "ol/layer/Group";

// Import bootstrap
// noinspection ES6UnusedImports
import * as bootstrap from 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Disclaimer} from "./src/disclaimer";
import {Fill, Icon, Stroke, Style} from "ol/style";
import VectorImageLayer from "ol/layer/VectorImage";
import AddressSearch from "./src/address-search";
import CircleStyle from "ol/style/Circle";
import VectorLayer from "ol/layer/Vector";

console.log(Config.WMS_URL)

const projection = getProjection('EPSG:3857');

const projExtent = projection.getExtent();
const startResolution = getWidth(projExtent) / 256;
const resolutions = new Array(22);
for (let i = 0, ii = resolutions.length; i < ii; ++i) {
    resolutions[i] = startResolution / Math.pow(2, i);
}
// const tileGrid = new TileGrid({
//   extent: [-13884991, 2870341, -7455066, 6338219],
//   resolutions: resolutions,
//   tileSize: [512, 256],
// });

const tileSize = 512;

const tileGrid = new TileGrid({
    extent: [613007, 5701224, 1217984, 6125048],
    resolutions: resolutions,
    tileSize: [tileSize, tileSize],
});

/**
 * Feature response:
 * geometry:
 *   coordinates: (2) [914485, 5940137]
 * id: "f7_pops.1"
 * properties:
 *   id: 1
 *   name: "P1"
 *   service_start: "2021-01-01"
 *   status: "planned/active"
 */

const popIconSize = 24

const popSource = WFSSource(
        Config.WFS_URL,
        'f7_pops',
        'EPSG:3857')

const stylePopActive = new Style({
    image: new Icon({
        src: marker,
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        // size: [popIconSize, popIconSize],
        width: popIconSize,
        height: popIconSize,
    })
})

const stylePopPlanned = new Style({
    image: new Icon({
        src: markerPlanned,
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        width: popIconSize,
        height: popIconSize,
    })
})

const renderIndex = (feature) => {
    return feature.get('status') === 'planned' ? 0 : 1
}

const popLayer = new VectorImageLayer({
    source: popSource,
    minZoom: 6,
    style: (feature) => {
        return feature.get('status') === 'planned' ? stylePopPlanned : stylePopActive
    },
    renderOrder: (f0, f1) => {
        return renderIndex(f0) - renderIndex(f1)
    }
})

// const popLayerPlanned = new WebGLPointsLayer({
//     source: popSource,
//     minZoom: 6,
//     style: {
//         "icon-src": markerPlanned,
//         "icon-width": popIconSize,
//         "icon-height": popIconSize,
//         "icon-rotate-with-view": false,
//         filter: ['==', ['get', 'status'], 'planned'],
//     },
// })

const ftthSource = WFSSource(Config.WFS_URL, 'ftth')

const createFtthStyle = (color, hasStroke) => {
    return new Style({
        image: new CircleStyle({
            radius: 8,
            fill: new Fill({color: `${color}`}),
            stroke: hasStroke ? new Stroke({color: '#ffff00', width: 2}) : undefined,
        })
    })
}


const styles = {
    'f7_rfo': createFtthStyle(Config.COLORS['primary']),
    'f7_rfm': createFtthStyle(Config.COLORS['primary-light']),
    'h7_rfo': createFtthStyle(Config.COLORS['secondary']),
    'h7_rfm': createFtthStyle(Config.COLORS['secondary-light']),
    'alex_na': createFtthStyle(Config.COLORS['tertiary']),
}

const stylesHighlight = {
    'f7_rfo': createFtthStyle(Config.COLORS['primary'], true),
    'f7_rfm': createFtthStyle(Config.COLORS['primary-light'], true),
    'h7_rfo': createFtthStyle(Config.COLORS['secondary'], true),
    'h7_rfm': createFtthStyle(Config.COLORS['secondary-light'], true),
    'alex_na': createFtthStyle(Config.COLORS['tertiary'], true),
}

console.log(styles)
const createFtthLayer = (status, color) => {
    return new VectorImageLayer({
        source: ftthSource,
        minZoom: 15,
        style: (feature) => {
            return feature.get('clicked') ? stylesHighlight[feature.get('class')] : styles[feature.get('class')]
        },
        renderOrder: (f0, f1) => {
            return f1.get('render_order') - f0.get('render_order')
        }
    })
}

// const createFtthLayer = (status, color) => {
//     return new WebGLPointsLayer({
//         source: ftthSource,
//         minZoom: 15,
//         style: {
//             "circle-radius": 8,
//             "circle-fill-color": color,
//             'circle-rotate-with-view': false,
//             'circle-stroke-width': ['match', ['get', 'clicked'], 1, 2, 0],
//             'circle-stroke-color': ['match', ['get', 'clicked'], 1, '#ffff00', color],
//             filter: ['==', ['get', 'state'], status],
//         }
//     })
// }

const ftthLayers = [
    createFtthLayer('f7_rfo', Config.COLORS['primary']),
    // createFtthLayer('f7_rfm', Config.COLORS['primary-light']),
    // createFtthLayer('h7_rfo', Config.COLORS['secondary']),
    // createFtthLayer('h7_rfm', Config.COLORS['secondary-light']),
    // createFtthLayer('alex_na', Config.COLORS['tertiary']),
]

// console.log(ftthLayers)

const ftthTileLayer = new TileLayer({
    source: new TileWMS({
        url: Config.WMS_URL,
        params: {'LAYERS': 'ftth', 'TILED': false},
        tileGrid: tileGrid,
    }),

    maxZoom: 16,
})

const layerGroupFtth = new LayerGroup({
    layers: [
        ftthTileLayer,
        ...ftthLayers,
    ]
})

const layerGroupPOPs = new LayerGroup({
    layers: [
        // popLayerPlanned,
        popLayer,
    ]
})

console.log(Config)



/**
 * Elements that make up the popup.
 */
const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');

/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
    element: container,
    autoPan: {
        animation: {
            duration: 250,
        },
    },
});

/**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
closer.onclick = function () {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};


const osmLayer = new TileLayer({
        source: new OSM(),
        className: 'layer-osm'
    },
)

//
// osmLayer.on('postrender', function (event) {
//     event.context.canvas.style.filter = 'brightness(109%) saturate(39%)'
// })

const debugLayer = new TileLayer({
    source: new TileDebug({
        template: 'z:{z} x:{x} y:{-y}',
        projection: osmLayer.getSource().getProjection(),
        tileGrid: osmLayer.getSource().getTileGrid(),
        zDirection: 1,
    }),
});


const olLayers = [
    osmLayer,
    // debugLayer,
    // ...layerData.base,
    layerGroupFtth,
    layerGroupPOPs,
]

const interactions = defaultInteractions({
    altShiftDragRotate: false,
    pinchRotate: false,
})

const map = new Map({
    target: 'map',
    layers: [
        // new TileLayer({
        //     source: new OSM(),
        // }),
        ...olLayers
    ],
    overlays: [overlay],
    view: new View(Config.DEFAULT_VIEW_OPTIONS),
    interactions: interactions,
});

/**
 * @type {Feature}
 */
let selected = null


/**
 * @type {Feature}
 */
let clicked = null

const mapElement = document.getElementById('map')

const getFeatureAtPixel = (pixel) => {
    let found = null;
    map.forEachFeatureAtPixel(pixel, function (feature) {
        feature.set('hover', 1)
        found = feature
        return true
    }, {
        hitTolerance: 4
    })
    return found
}

const updateSelected = (evt, new_selected) => {
    if (new_selected && new_selected === selected) {
        return
    }

    if (selected !== null) {
        selected.set('hover', 0)
        selected = null
    }

    if (new_selected) {
        selected = new_selected
    } else {
        const temporarySelected = getFeatureAtPixel(evt.pixel)
        if (temporarySelected) {
            // const line = new LineString([temporarySelected.getGeometry().getCoordinates(), evt.coordinate])
            const tempPixel = map.getPixelFromCoordinate(temporarySelected.getGeometry().getCoordinates())
            const pixelDistance = Math.sqrt(
                Math.pow(tempPixel[0] - evt.pixel[0], 2) +
                Math.pow(tempPixel[1] - evt.pixel[1], 2)
            )
            // Sanity check of distance between evt and feature because firefox jumps around
            //console.log('distance', line.getLength(), 'px-distance', pixelDistance, 'px-coord', evt.coordinate, 'px-coord-calc', map.getCoordinateFromPixelInternal(evt.pixel))
            if (pixelDistance < 20) {
                selected = temporarySelected
            } else {
                console.warn('firefox bug, ignoring feature. Pixel distance to target: ' + pixelDistance + 'px')
            }
        }
    }

    if (selected)
        selected.set('hover', 1)
}

map.on('pointermove', function (e) {
    updateSelected(e, null)

    if (selected !== null) {
        mapElement.className = 'hover'
    } else {
        mapElement.className = ''
    }
})

const sidebar = new PointInfo(document.getElementById('point-info'))

/**
 * @param {Feature} selected
 */
function handlePointClick(selected) {
    if (!selected) {
        return
    }

    if (clicked !== null) {
        clicked.set('clicked', 0)
        clicked = null
    }
    clicked = selected
    clicked.set('clicked', 1)
}


map.on('click', function (evt) {
    // Let's ignore the current hovered feature to try and prevent the random jumps in firefox
    updateSelected(evt, null)
    handlePointClick(selected)
    console.log('click', evt, selected && selected.getProperties())

    if (!selected) {
        overlay.setPosition(undefined);
        return
    }

    if (selected.get('class') !== undefined) {
        overlay.setPosition(undefined);
        sidebar.updateSidebar(selected)
        return
    }

    if (selected.get('name') === undefined) {
        overlay.setPosition(undefined);
        return
    }

    const public_pop_name = selected.get('public_pop_name')

    content.innerHTML = '<b>' + selected.get('name') + '</b><br>' + public_pop_name.replace(/ /g, '&nbsp;')
    overlay.setPosition(selected.getGeometry().getCoordinates());
});


const link = new Link();

const cbFtth = document.getElementById('input-ftth')
const cbPops = document.getElementById('input-pops')

attachLayerCheckbox('ftth', cbFtth, link, layerGroupFtth)
attachLayerCheckbox('pops', cbPops, link, layerGroupPOPs)

map.addInteraction(link)

map.render()

window.map_obj = map

const addressSearch = new AddressSearch(Config.NOMINATIM_URL, map, 16)
const form = document.getElementById('search-bar')
if (form) {
    addressSearch.attachSearchForm(document.getElementById('search-bar'))
}


Disclaimer('faq-modal', 'disclaimer')
